<template>
  <div>
    <div class="welcome">
      <div style="width: 90%; max-width: 800px">
        <div class="head">
          <div class="message">
            <h2 v-if="user && user.username">Welcome {{ user.username }}</h2>
            <h4>What will you like to do first?</h4>
          </div>
          <!-- 
          <div class="wallet">
            <span class="ti">wallet balance</span>
            <span class="bal">0.00</span>
          </div> -->
        </div>

        <template v-if="$orgDetails ? $orgDetails.token : null">
          <div class="kyb-warn" v-if="!$orgDetails.token.kyc">
            <img :src="require('@/assets/icons/danger.svg')" alt="" />
            <span>
              Account currently <b>on hold</b>, please complete KYB for your
              organisation to access all the tools for invoice processing,
              payment flows and reconciliation.
            </span>
            <button @click="showDialog">Take Action</button>
          </div>
        </template>
        <div class="step-container">
          <!-- 1 -->
          <div class="step-container__step">
            <div class="bodx">
              <div class="icon">
                <img :src="require('@/assets/icons/like.svg')" />
              </div>

              <span class="title">Approve vendor invoices</span>
              <span class="description"
                >Control cash leaving your business. Before paying that vendor,
                receive, digitize and approve the invoice.
              </span>
            </div>
            <div class="btnx">
              <v-btn
                color="primary"
                @click="invoiceDialog = true"
                elevation="1"
                style="text-transform: none"
              >
                <v-icon>mdi-chevron-right</v-icon> Start Here</v-btn
              >
            </div>
          </div>

          <!-- 2 -->
          <div class="step-container__step">
            <div class="bodx">
              <div class="icon">
                <img :src="require('@/assets/icons/request.svg')" />
              </div>

              <span class="title">Collect sales request online</span>
              <span class="description"
                >Send invoices that automatically reconcile. Collect request for
                services or products online or offline with forms that generate
                invoices.
              </span>
            </div>
            <div class="btnx">
              <v-btn
                color="primary"
                elevation="1"
                @click="$router.push({ name: 'Form' })"
                style="text-transform: none; margin: 10px auto"
              >
                <v-icon>mdi-chevron-right</v-icon> Start Here</v-btn
              >
            </div>
          </div>

          <!-- 3 -->
          <div class="step-container__step">
            <div class="bodx">
              <div class="icon">
                <img :src="require('@/assets/icons/organize.svg')" />
              </div>

              <span class="title">Organize your payment process</span>
              <span class="description"
                >Configure what should happen after you receive or send a
                payment. Design the payment workflow your internal process needs
              </span>
            </div>
            <div class="btnx">
              <v-btn
                color="primary"
                elevation="1"
                style="text-transform: none; margin: 10px auto"
              >
                <v-icon>mdi-chevron-right</v-icon> Start Here</v-btn
              >
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="icon">
            <img :src="require('@/assets/icons/video.svg')" />
          </div>
          <div class="msg">
            <b> Let’s get you started.</b> Watch an interactive tutorial to
            learn the basics of payment operations with Hyphen
          </div>
          <div>
            <button><v-icon>mdi-chevron-right</v-icon> Watch Now</button>
          </div>
        </div>
      </div>
    </div>
    <Company ref="company" />

    <v-dialog max-width="530px" v-model="invoiceDialog">
      <div class="invoice-onboarding">
        <div class="invoice-onboarding__head">
          <span>Onboard vendor invoices</span>

          <v-btn color="primary" icon @click="invoiceDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div class="invoice-onboarding__bloc-head">
          <span class="bloc">First Invoice</span>
        </div>

        <div class="invoice-onboarding__body">
          To begin approving vendor invoices, forward existing invoices to
          <a
            v-if="token && token.hypn_email"
            :href="`mailto:${token.hypn_email}`"
            class="mail"
            >{{ token.hypn_email }}</a
          >

          this is the invoice collection email address Hyphen has generated for
          your business. To see it in action
          <a href="/inbox" class="link">click here</a> to recieve your first
          invoice from our team.

          <div class="video">
            <div>
              <v-btn color="#F7E8C2" icon
                ><v-icon size="40px">mdi-play</v-icon></v-btn
              >
              <span>Watch now</span>
            </div>
          </div>

          <div style="text-align: right; margin-top: 15px">
            <v-btn
              color="primary"
              to="/inbox"
              elevation="5"
              large
              style="padding: 18px 10px 18px 8px; text-transform: none"
            >
              <v-icon>mdi-chevron-right</v-icon> View Invoices</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Company from "@/components/kyc/company.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedAction: null,
      publicPath: process.env.BASE_URL,
      dialog: false,
      invoiceDialog: false,
      token: null,
    };
  },
  components: {
    Company,
  },
  mounted() {
    //  this.$store.dispatch("auth/fetchUserDetails");
    console.log(process.env.NODE_ENV);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    async getOrganizationToken() {
      try {
        const { data } = await this.$store.dispatch(
          "organizations/getOrganizationToken"
        );
        this.token = data.data;
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.log(JSON.stringify(error, null, 2));
        }
      }
    },

    closeDialog() {
      this.dialog = false;
    },

    triggerDialog(item) {
      this.selectedAction = item;
      this.dialog = true;
    },
    showDialog() {
      this.$refs.company.show(true);
      //console.log(this.$refs[ref]);
    },

    async submitBankCode(response) {
      const { data } = await this.$store.dispatch(
        "organizations/addBank",
        response
      );
      console.log(JSON.stringify(data, null, 2));
    },

    //method to launch the Mono app
    launchMono() {
      // console.log(process.env.VUE_APP_MONO_KEY);
      const options = {
        onSuccess: (response) => {
          console.log(JSON.stringify(response));

          this.submitBankCode(response);

          // let resp =
          // console.log(JSON.stringify(resp, null, 2));
        },

        onClose: function () {
          console.log("user closed the widget.");
        },
      };
      this.$launchMono(options);
    },
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.getOrganizationToken();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    // mobile view

    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: unset;
      align-items: unset;
      gap: 20px;
    }

    .message {
      @media screen and (max-width: 600px) {
        order: 2;
      }

      h2 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #19283d;
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #525e6e;
      }
    }

    .wallet {
      text-align: right;

      @media screen and (max-width: 600px) {
        order: 1;
      }

      .ti {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        display: block;
        color: #2bd5ae;
      }

      .bal {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        /* identical to box height */

        /* hyphen Blue */

        color: #19283d;
      }
    }
  }

  .kyb-warn {
    display: flex;
    align-items: center;
    background: #ffffff;
    border-left: 4px solid #ff6a6a;
    padding: 25px;
    gap: 30px;
    margin: 40px 0px;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #19283d;
    }

    button {
      white-space: nowrap;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      padding: 10px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.636364px;
      color: #19283d;
      background: #f4f5f6;
      border-radius: 4px;
    }
  }

  .icon {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // responsive grid

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    grid-gap: 20px;

    margin: auto;
    cursor: pointer;

    &__step {
      background: #f6f3ee;
      border-radius: 4px;
      padding: 50px 25px;
      text-align: center;
      // position: relative;
      display: flex;
      flex-direction: column;

      .bodx {
        flex: 1;

        .icon {
          margin: 20px auto;
          margin-bottom: 20px;
        }

        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          text-align: center;

          /* body text */

          color: rgba(25, 40, 61, 0.8);
        }

        .description {
          display: block;
          margin: 20px 0px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 21px;
          /* or 200% */

          text-align: center;
          padding: 20px auto;

          /* body text */

          color: rgba(25, 40, 61, 0.8);
        }
      }

      .btnx {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        // from tablet up

        // @media (min-width: 768px) {
        //   left: 23%;
        //   position: absolute;
        //   bottom: 20px;
        // }
      }

      &:hover {
        outline: 1px solid #d7a47b;
      }
    }
  }

  .footer {
    background: #f6f3ee;
    border: 1px solid #f6f3ee;
    border-radius: 4px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;

    // mobile view

    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: unset;
      align-items: center;
      text-align: center;
      gap: 20px;
    }

    .msg {
      flex: 1;
    }

    button {
      display: inline-flex;
      align-items: center;
      background: #fff;
      border-radius: 3.02222px;
      padding: 5px;
      padding-right: 9px;
    }
  }
}

.invoice-onboarding {
  font-family: "Inter";
  font-style: normal;
  border-radius: 8px;

  &__head {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    background-color: #fff;
    color: var(--v-primary-base);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 32px;
    padding-right: 12px;
    border-radius: 8px 8px 0 0;
  }

  &__bloc-head {
    padding: 0px 32px;

    background: #f6f3ee;
    border-bottom: 1px solid rgba(127, 145, 155, 0.3);

    .bloc {
      margin-top: 40px;
      display: inline-block;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      text-transform: uppercase;
      padding: 8px 10px;

      /* hyphen Blue */

      color: var(--v-primary-base);
      border-bottom: 3px solid var(--v-primary-base);
    }
  }

  &__body {
    background-color: #fff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    padding: 32px;
    /* or 200% */

    /* body text */

    color: rgba(25, 40, 61, 0.8);

    .mail {
      font-weight: 600;
      color: #19283d;
      text-decoration: none;
    }

    .link {
      color: #5b67ba;
    }

    .video {
      height: 200px;
      background-color: #000;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
      text-align: center;

      span {
        display: block;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }
}
</style>
